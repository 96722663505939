import React, { useState, useEffect } from "react";
var states = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
];
var countries = [
  "Austria",
  "Australia",
  "Belgium",
  "Bulgaria",
  "Canada",
  "Cyprus",
  "Czech Republic",
  "Germany",
  "Denmark",
  "Estonia",
  "Greece",
  "Spain",
  "Finland",
  "France",
  "Croatia",
  "Hungary",
  "Ireland",
  "Italy",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Sweden",
  "Slovenia",
  "Slovakia",
  "United Kingdom",
  "United States",
];

var productTypes = [
  {
    name: "Fully Taxable",
    value: "fully taxable",
  },
  {
    name: "SaaS",
    value: "SaaS",
  },
  {
    name: "Clothing",
    value: "clothing",
  },
  {
    name: "Supplements",
    value: "supplements",
  },
];
const Form = ({ submit }) => {
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("United States");
  const [productType, setProductType] = useState(productTypes[0]);
  let stateCode = "";
  useEffect(() => {
    if (state) {
      stateCode = states.find((s) => s.name === state).code;
    }
  }, [state]);
  return (
    <form
    className="w-full space-y-2"
    onSubmit={(e) => {
      submit(
        e,
        streetAddress,
        city,
        zipCode,
        country,
        productType,
        stateCode
      );
    }}
  >
    <input
      type="text"
      placeholder="Street address"
      className="input"
      value={streetAddress}
      onChange={(e) => setStreetAddress(e.target.value)}
    />
    <input
      type="text"
      placeholder="City "
      className="input"
      value={city}
      onChange={(e) => setCity(e.target.value)}
    />

    <input
      type="text"
      placeholder="Zip/postal code"
      className="input"
      value={zipCode}
      onChange={(e) => setZipCode(e.target.value)}
    />

    <select
      className="cursor-not-allowed input"
      onChange={(e) => {
        setCountry(e.target.value);
      }}
      value={country}
      disabled
    >
      {countries.map((country) => (
        <option key={country} value={country}>
          {country}
        </option>
      ))}
    </select>
    <select
      placeholder="State"
      className="input"
      onChange={(e) => {
        setState(e.target.value);
      }}
      value={state}
    >
      <option value="">Select a state</option>
      {states.map((state) => (
        <option key={state.code} value={state.name}>
          {state.name}
        </option>
      ))}
    </select>
    <select
      className="input"
      onChange={(e) => {
        setProductType(e.target.value);
      }}
      value={productType}
    >
      {productTypes.map((productType) => (
        <option key={productType.value} value={productType.value}>
          {productType.name}
        </option>
      ))}
    </select>
    <button
      type="submit"
      className="w-full p-3 text-center rounded bg-background"
    >
      <span className="uppercase">Look up rate</span>
    </button>
  </form>
  );
};

export default Form;
